import { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Navigation, Pagination } from 'swiper/modules';
import ReactPlayer from "react-player";
import ReactGA from 'react-ga4';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './styles/app.scss'
import { Footer } from "./components/Footer";

function App() {
  return (
    <div className="App">
      <div className="top-bar">
        <img src={`${process.env.PUBLIC_URL}/img/ys.webp`} alt="" className="top-image" />
      </div>
      <HeroSlider />
      <section className="topics">
        <div className="section-title">
          <h1>TOPICS</h1>
        </div>
        <TopicSlider />
      </section>
      <Prologue />
      <Map />
      <OnlineStore />
      <Official/>
      <Footer/>
    </div>
  );
}

const HeroSlider = () => {
  return (
    <section className="hero-slider" style={{ position: "relative", padding: "40px 0" }}>
      <Swiper
        modules={[Autoplay,Pagination, Navigation]}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className} custom-bullet"></span>`;
          },
        }}
        navigation
        slidesPerView={1}
        loop={true}
        speed={500}
        autoplay={{ delay: 3000 }}
      >
        <SwiperSlide>
          <div className="hero-slide" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/background.webp)` }}>
            <a href="https://nisamerica.com/ys-x/" target="_blank" rel="noopener noreferrer"onClick={()=>{
                      ReactGA.event({
                        category: `Hero - Ys X`,
                        action: `Hero - Ys X`,
                        label: `Hero - Ys X`,
                      });
                    }}>
              
              <img src={`${process.env.PUBLIC_URL}/img/ys-x-logo.png`} alt="Ys X Logo Art" className="hero-logo" />
              <img src={`${process.env.PUBLIC_URL}/img/ys-x-bg.webp`} alt="Ys X Background Art" className="hero-image" />
            </a>
            <img src={`${process.env.PUBLIC_URL}/img/ys-x-bg.webp`} alt="Ys X Background Art" className="hero-bg" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="hero-slide" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/background.webp)` }}>
          <a href="https://nisamerica.com/ys-ix/" target="_blank" rel="noopener noreferrer" onClick={()=>{
                      ReactGA.event({
                        category: `Hero - Ys IX`,
                        action: `Hero - Ys IX`,
                        label: `Hero - Ys IX`,
                      });
                    }}>
              
              <img src={`${process.env.PUBLIC_URL}/img/ys-ix-logo.png`} alt="Ys IX Logo Art" className="hero-logo" />
              <img src={`${process.env.PUBLIC_URL}/img/ys-ix-bg.webp`} alt="Ys IX Background Art" className="hero-image" />
            </a>
            <img src={`${process.env.PUBLIC_URL}/img/ys-ix-bg.webp`} alt="Ys IX Background Art" className="hero-bg" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="hero-slide" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/background.webp)` }}>
            <a href="https://ysviii.com/" target="_blank" rel="noopener noreferrer"
              onClick={()=>{
                ReactGA.event({
                  category: `Hero - Ys VIII`,
                  action: `Hero - Ys VIII`,
                  label: `Hero - Ys VIII`,
                });
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/img/ys-viii-logo.png`} alt="Ys VII Logo" className="hero-logo" />
              <img src={`${process.env.PUBLIC_URL}/img/background.webp`} alt="Ys VII Background Art" className="hero-image" />
            </a>
            <img src={`${process.env.PUBLIC_URL}/img/background.webp`} alt="Ys VII Background Art" className="hero-bg" />
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

const topics = [
  {
    image: 'bundle',
    category: 'EVENT',
    date: 'Aug 16, 2024',
    title: `Check out the exclusive bundles that will be sold at #PAXWest 2024!`,
    url: 'https://nisamerica.com/pax/west2024/'
  },
  {
    image: 'le',
    category: 'store',
    date: 'Aug 14, 2024',
    title: 'Have you seen the #YsX: Nordics Limited Edition?!',
    url: 'https://store.nisamerica.com/products/ys-x'
  },
  {
    image: 'falcom-page',
    category: 'store',
    date: 'August 05, 2024',
    title: 'Nihon Falcom Store Page',
    url: 'https://store.nisamerica.com/collections/nihon-falcom'
  },
  {
    image: 'pv1',
    category: 'TRAILER',
    date: 'Aug 7, 2024',
    title: 'Meet Adol Christin, Karja Balta, and Dogi!',
    url: 'https://www.youtube.com/watch?v=NUwGTNapTZA&list=PLLbEnddEjPftCLF-byJn5pnq0hTTQQ209&index=5'
  },
  {
    image: 'x-villain',
    category: 'TRAILER',
    date: 'Aug 7, 2024',
    title: 'Meet the villainous Griegr leaders Óðr, Lǫgr, and Jörð from #YsX: Nordics! ',
    url: 'https://www.youtube.com/watch?v=8D-vY7CQAAc&list=PLLbEnddEjPftCLF-byJn5pnq0hTTQQ209&index=1'
  },
  {
    image: 'pv3',
    category: 'TRAILER',
    date: 'Aug 6, 2024',
    title: 'Meet Grimson Balta, Mirabel Asrad, Rafe Evelies, and Nameless Old Man!',
    url: 'https://www.youtube.com/watch?v=JeJExEQ13zw&list=PLLbEnddEjPftCLF-byJn5pnq0hTTQQ209&index=3'
  },
  {
    image: 'pv2',
    category: 'TRAILER',
    date: 'Jul 31, 2024',
    title: 'Meet Grenn Berge, Rosalind Rusveri, and Cruz Carpent!',
    url: 'https://www.youtube.com/watch?v=rNqAt5aoXLI&list=PLLbEnddEjPftCLF-byJn5pnq0hTTQQ209&index=4'
  },
  {
    image: 'x-ys',
    category: 'EVENT',
    date: 'Jul 30, 2024',
    title: `We're bringing #YsX: Nordics to #PAXWest 2024!`,
    url: 'https://nisamerica.com/pax/west2024/'
  },

];

const TopicSlider = () => {
  return (
    <div className="topics-carousel">
      <Swiper
        modules={[Navigation]}
        navigation
        spaceBetween={50} // Space between slides
        slidesPerView={'auto'} // Automatically determine the number of slides to show
      >
        {topics.map((topic, index) => (
          <SwiperSlide key={index} style={{ width: 'auto' }}> {/* Ensure each slide has dynamic width */}
            <TopicSlide
              image={topic.image}
              category={topic.category}
              date={topic.date}
              title={topic.title}
              url={topic.url}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const TopicSlide = ({ image, category, date, title, url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer" onClick={()=>{
    ReactGA.event({
      category: `Topic - ${title}`,
      action: `Topic - ${title}`,
      label: `Topic - ${title}`,
    });
  }}>
    <div className="topic-slide">
      <div className="topic-image">
        <img src={`${process.env.PUBLIC_URL}/img/topics/${image}.webp`} alt={title} />
      </div>
      <div className="topics-details">
        <div className={`topic-category topic-${category}`}>{category}</div>
        <h3 className="topics-date">{date}</h3>
      </div>
      <div className="topics-caption">
        <h3 className="topics-title">
          {title.length > 105 ? `${title.substring(0, 105)}...` : title}
        </h3>
      </div>
    </div>
  </a>
);

const Prologue = () => {

  const [viewMore, showMore] = useState(false)

  return (
    <section className="prologue-section">
      <h1 className="section-title">
        PROLOGUE
      </h1>

      <p className="prologue-text">
        Do you know the name Adol Christin?<br/><br/>

        Adol Christin... <br/><br/>
        
        Roughly one thousand and several hundred years ago, he was born on a mountain in a small, unknown village nestled in the northeast of Europe on the Eresian continent. A bold and courageous adventurer, he traveled all across Europe and many a foreign land from the age of sixteen till his disappearance at sixty-three.<br/><br/>
      </p>
      <div className="video-container">
        <div className="video-wrapper">
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=agHBvi0dzJk"
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <button className={`show-more ${viewMore && 'invisible'}`} onClick={()=>{
        showMore(true)
      }}>
        Show More
      </button>
      <p className={`${!viewMore && 'invisible'}`} style={{paddingTop: '50px'}}>
        He, who was born as the mere son of a peasant, was known by all as an active youth with a boundless curiosity.<br/><br/>

        More than anything, he wished to know of the outside world. It was not until one fateful day, when a traveler came upon his village, that he was inspired to set off on an adventure of his very own.<br/><br/>

        In that day and age, when travel was primarily only done on foot or by boat, the sheer breadth of his undertakings was nothing short of staggering.<br/><br/>

        It is said that he journeyed as far south as Central Afroca and as far east as the Tigres River in the Orietta region, even setting his sights on the North Pole in his twilight years—an endeavor that supposedly ended in failure.<br/><br/>

        From all of this, we can surmise that his spirit of adventure, inquisitive nature, and deep longing for far-off lands were considerable indeed. Wherever he went, he would record the events that transpired in journals, which he left behind for future generations.<br/><br/>

        Classic examples of his tales include:<br/><br/>
        　• "Five Dragons of Altago"<br/><br/>
        　• "The Great Forest of Celceta"<br/><br/>
        　• "Kefin, Lost Capital of Sand"<br/><br/>
        ...among many, many more.<br/><br/>

        Over a hundred such volumes are currently stored in the basement of his birth home, where—unlike their author, who swept across the Western world like a storm—they rest peacefully. It is through reading these tomes that we can come to learn just what manner of adventures unfolded before him.
      </p>
      <button className={`show-more ${!viewMore && 'invisible'}`} onClick={()=>{
        showMore(false)
      }}>
        Hide
      </button>
    </section>
  )
}

const location = [
  {
    title: "Esteria & the Ancient Kingdom of Ys - 17 Y.O.",
    subtitle: "Adventure Journal: Ancient Ys Vanished",
  },
  {
    title: "Obelia Gulf",
    subtitle: "Adventure Journal: The Normans' Paradise Lost",
    game:'Ys X: Nordics'
  },
  {
    title: "Celceta",
    subtitle: "Adventure Journal: The Great Forest of Celceta",
  },
  {
    title: "Felghana - 19 Y.O.",
    subtitle: "Adventure Journal: Felghana Archives",
  },
  {
    title: "Xandria - 20 Y.O.",
    subtitle: "Adventure Journal: Kefin, Lost Capital of Sand",
  },
  {
    title: "Seiren Island - 21 Y.O.",
    subtitle: "Adventure Journal: Travelogue of the Gaete Sea",
    game:'Ys VIII: Lacrimosa of DANA'
  },
  {
    title: "Canaan Islands - 23 Y.O.",
    subtitle: "Adventure Journal: Searching for the Winged Ones",
  },
  {
    title: "The Principality of Altago",
    subtitle: "Adventure Journal: Five Dragons of Altago",
  },
  {
    title: "Balduq - 24 Y.O.",
    subtitle: "Adventure Journal: Balduq Prison",
    game:'Ys IX: Monstrum Nox'
  }
]


const Map = () => {
  
  return (
    <section className="map-section">
      <div className="map">
        <img src={`${process.env.PUBLIC_URL}/img/ys-map.png`} alt="" />
      </div>
      <div className="map-locations">
        {
          location.map((location, index)=>(
            <Location number={index} title={location.title} subtitle={location.subtitle} game={location.game}/>
          )
          )
        }
      </div>
    </section>
  )
}

const Location = ({number, title, subtitle, game}) => {
  return (
    <div className="location">
      <div className="location-title">
        <div className="marker">
          {
            number+1
          }
        </div>
        <div className="place">
          <h1 className="location-main">
            { title }
          </h1>
          <h2 className="location-subtitle">
            { subtitle }
          </h2>
          {
            game &&
            <h3 className="location-game">
              { game }
            </h3>
          }
        </div>
      </div>
    </div>
  )
}

const OnlineStore = () => {
  return (
    <section className="store-section">
      <h1 className="section-title">
        ONLINE STORE
      </h1>
      <div className="store-carousel">
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className} custom-bullet"></span>`;
          },
        }}
        navigation
        spaceBetween={50} // Space between slides
        slidesPerView={'auto'} // Automatically determine the number of slides to show
        loop={true}
        speed={500}
        autoplay={{ delay: 3000 }}
        centeredSlides={true}
      >
        {
          storeItems.map((item, index)=>(
            <SwiperSlide key={index} style={{ maxWidth: '500px', width: '90vw', minWidth: '300px' }}>
              <StoreItem image={item.image} title={item.title} link={item.link}/>
            </SwiperSlide>
          ))
        } 
      </Swiper>
      </div>      
      <a href="https://store.nisamerica.com/collections/nihon-falcom" target="_blank" rel="noopener noreferrer">
        <button className="store-button">
          NISA Online Store - Falcom Page
        </button>
      </a>
    </section>
  )
}

const StoreItem = ({image, title, link}) => (
  <div className="store-item">
    <div className="store-image">
      <img src={`${process.env.PUBLIC_URL}/img/products/${image}.webp`} alt="" />
    </div>
    <h1 className="store-item_title">
      {title}
    </h1>
    <a href={link} target="_blank" rel="noopener noreferrer" onClick={()=>{
    ReactGA.event({
      category: `Store - ${title}`,
      action: `Store - ${title}`,
      label: `Store - ${title}`,
    });
  }}>
      <button className="store-button">
        NISA Online Store
      </button>
    </a>
  </div>
)

const storeItems = [
  {
    image: 'ys-x-le',
    title: 'Ys X: Nordics Limited Edition',
    link: 'https://store.nisamerica.com/products/ys-x?_pos=2&_sid=1f74b5602&_ss=r'
  },
  {
    image: 'ys-viii',
    title: 'Ys VIII: Lacrimosa of DANA',
    link: 'https://store.nisamerica.com/products/ys-viii-lacrimosa-of-dana-standard-edition-ps4'
  },
  {
    image: 'karja',
    title: 'Ys X: Nordics - Chibi Karja Keychain',
    link: 'https://store.nisamerica.com/products/ys-x-nordics-chibi-karja-keychain?_pos=3&_sid=1f74b5602&_ss=r'
  },
  {
    image: 'adol',
    title: 'Ys X: Nordics - Chibi Adol Keychain',
    link: 'https://store.nisamerica.com/products/ys-x-nordics-chibi-adol-keychain?_pos=4&_sid=1f74b5602&_ss=r'
  },
  {
    image: 'coin',
    title: `Ys X: Nordics: The Runestones' Legacy Acrylic Stand`,
    link: 'https://store.nisamerica.com/products/ys-x-nordics-the-runestones-legacy-acrylic-stand?_pos=5&_sid=1f74b5602&_ss=r'
  },
  {
    image: 'ys-viii-acrylic',
    title: `Ys VIII: Lacrimosa of DANA - "Dana's Respite" Acrylic Art Print`,
    link: 'https://store.nisamerica.com/products/ys-viii-lacrimosa-of-dana-dana-s-respite-acrylic-print?_pos=6&_sid=1f74b5602&_ss=r'
  },
  {
    image: 'adol-plush',
    title: `Ys X: Nordics: Lil' Adventurer Adol Plush`,
    link: 'https://store.nisamerica.com/products/lil-adventurer-adol-plush?_pos=7&_sid=1f74b5602&_ss=r'
  },
  {
    image: 'adol-acrylic',
    title: 'Ys X: Nordics: Adol the Red Acrylic Stand',
    link: 'https://store.nisamerica.com/products/ys-x-nordics-adol-the-red-acrylic-stand?_pos=8&_sid=1f74b5602&_ss=r'
  },
  {
    image: 'karja-plush',
    title: `Ys X: Nordics: Lil' Pirate Princess Karja Plush`,
    link: 'https://store.nisamerica.com/products/ys-x-nordics-lil-pirate-princess-karja-plush?_pos=9&_sid=1f74b5602&_ss=r'
  },
  {
    image: 'karja-acrylic',
    title: `Ys X: Nordics: Azure-Eye Karja Acrylic Stand`,
    link: 'https://store.nisamerica.com/products/ys-x-nordics-azure-eye-karja-acrylic-stand?_pos=10&_sid=1f74b5602&_ss=r'
  },
  {
    image: 'ys-ix-acrylic',
    title: 'Ys IX: Monstrum Nox - "Herald of the Night" Acrylic Art Print',
    link: 'https://store.nisamerica.com/products/ys-ix-monstrum-nox-herald-of-the-night-acrylic-print?_pos=11&_sid=1f74b5602&_ss=r'
  },
  {
    image: 'ys-viii-acrylic-2',
    title: `Ys VIII: Lacrimosa of DANA - "Lacrimosa of Dana" Acrylic Art Print`,
    link: 'https://store.nisamerica.com/products/ys-viii-lacrimosa-of-dana-lacrimosa-of-dana-acrylic-print?_pos=12&_sid=1f74b5602&_ss=r'
  },


]

const Official = () => (
  <section className="official-section">
    <div className="section-title">
      OFFICIAL WEBSITES
    </div>
    <WebsitePortal bg="ys-x-bg" image="ys-x" video="Cr5_4eGWQio" url="https://nisamerica.com/ys-x/" product="https://nisamerica.com/ys-x/products" name="ys-x"/>
    <div className="ys-x_about">
      <h3 className="ys-x_caption">
        The Latest Installment
      </h3>
      <h1 className="ys-x_title">
        Adol the Adventurer heads to the far-flung seas of the north!
      </h1>
      <p className="ys-x_body">
        Obelia Gulf: an archipelago comprised of numerous islands both big and small.<br/><br/>

        Here, the young adventurer Adol Christin meets the Normans, a seafaring people, along with the Griegr, deathless beings that terrorize the populace.<br/><br/>

        Who exactly are they, and what could they be after? Unveil these mysteries and sail the ocean blue on your very own boat in the franchise's newest entry, Ys X: Nordics!<br/><br/>

        The adventurous thrills that define the series return with new twists, from exploring vast waters with nautical charts as your guide to engaging in epic naval battles with enemy fleets!<br/><br/>

        Return to the early days of protagonist Adol Christin's travels and embark on a new adventure in the northern seas of Obelia Gulf!<br/><br/>
      </p>
      <h2 className="ys-x_date">
        Ys X: Nordics<br/>
        On sale October 25, 2024!
      </h2>
      <a href="https://nisamerica.com/ys-x/products" target="_blank" rel="noopener noreferrer">
        <button className="store-button" onClick={()=>{
            ReactGA.event({
              category: `Preorder - Ys X`,
              action: `Preorder - Ys X`,
              label: `Preorder - Ys X`,
            });
          }}>
          Preorder Now
        </button>
      </a>
    </div>
    <WebsitePortal bg="ys-ix-bg" image="ys-ix" video="gm476waenZg" url="https://nisamerica.com/ys-ix/" product="https://nisamerica.com/ys-ix/about/product" name="ys-ix"/>
    <WebsitePortal bg="ys-viii-bg" image="ys-viii" video="uq5Sl2rp1b4" url="https://ysviii.com/" product="https://nisamerica.com/games/ys-viii-lacrimosa-dana#preorder" name="ys-vii"/>
  </section>
)

const WebsitePortal = ({bg, image, video, url, product, name}) => {

  const [modal, setModalOpen] = useState(false)

  return (
    <>
      <div className={`modal-container ${modal && "modal-visible"}`}>
        <button className="modal-close" onClick={()=>{setModalOpen(false)}}>
          X
        </button>
        <div className="video-container">
          <div className="video-wrapper">
            <ReactPlayer
              className="react-player"
              url={`https://www.youtube.com/watch?v=${video}`}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
      <div className={`portal-container ${bg}`}>
        <img className="portal-logo" src={`${process.env.PUBLIC_URL}/img/${image}-logo.png`} alt={bg + 'logo'} />
        <div className="portal-contents">
          <button className="trailer-button" onClick={()=>{setModalOpen(true)}}>
            <img src={`https://img.youtube.com/vi/${video}/maxresdefault.jpg`} alt="" />
          </button>               
          <a href={url} target="_blank" rel="noopener noreferrer" onClick={()=>{
            ReactGA.event({
              category: `Official - ${name}`,
              action: `Official - ${name}`,
              label: `Official - ${name}`,
            });
          }}>
            <button className="website-button" style={{backgroundColor: ""}}>
              Official Website
            </button>
          </a>
          <a href={product} target="_blank" rel="noopener noreferrer" onClick={()=>{
            ReactGA.event({
              category: `Product Page - ${name}`,
              action: `Product Page - ${name}`,
              label: `Product Page - ${name}`,
            });
          }}>
            <button className="product-button" style={{backgroundColor: ""}}>
              {
                bg === 'ys-x-bg' ? 'Preorder Now' : "Buy Now"
              }
            </button>
          </a>
        </div>
      </div>
  </>
  )
}

export default App;
