import axios from 'axios'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'

import '../styles/footer.scss';


export const Footer = () => {
    const [email, setEmail] = useState('')
    const [developer, setDeveloper] = useState()
    const [developerFilePath, setDeveloperFilePath] = useState([])
    const [developerCR, setDeveloperCR] = useState()
    const [platforms, setPlatforms] = useState([])

    const gameData = {
        path: 'ys-x',
        platforms: [],
    }
    const platformFilePaths = {
        'PlayStation®5': 'ps5.svg',
        'PlayStation®4': 'ps4-white.svg',
        'PlayStation®4-5': 'ps4-ps5.svg',
        Xbox: 'xbox-seriesxs.svg',
        'Xbox One': 'xbox-one.svg',
        'Nintendo Switch™': 'nsw.svg',
        PC: 'pc.svg',
        Steam: 'steam-white.svg',
    }

    useEffect(() => {
        axios
            .get(`https://www.nisamerica.com/api/game/${gameData.path}`)
            .then((response) => {
                const developer = "Nihon Falcom";
                const developerCR = `© Nihon Falcom Corporation. All Rights Reserved. Licensed to and published by NIS America, Inc.`
                const platforms = ['PlayStation®5']
                setPlatforms(platforms)
                setDeveloper(developer)
                setDeveloperCR(developerCR)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const getdeveloperFilePaths = async () => {
        try {
            let response
            response = await axios.get('https://www.nisamerica.com/public/files/developerFilePath.json')
            setDeveloperFilePath(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getdeveloperFilePaths()
    }, [])

    const modifiedPlatforms = (() => {
        const hasPlayStation4And5 =
            gameData.platforms.includes('PlayStation®5') && gameData.platforms.includes('PlayStation®4')

        if (hasPlayStation4And5) {
            return [
                'PlayStation®4-5',
                ...gameData.platforms.filter(
                    (item) => !(item.includes('PlayStation®5') || item.includes('PlayStation®4'))
                ),
            ]
        } else {
            return gameData.platforms
        }
    })()
    function covertPlatformFilePath(items) {
        return platformFilePaths[items] || ''
    }

    function covertDeveloperFilePath(developer) {
        const foundDeveloper = developerFilePath.find((item) => item.developer === developer)
        return foundDeveloper ? foundDeveloper.developerFilePath : ''
    }

    return (
        <footer className='footer-wrapper'>
            <div className="footer-content">
            <div className='socials-wrapper'>
                <div className='socials'>
                    <div className=''>
                        <a
                            onClick={() => {
                                ReactGA.event({
                                    action: 'click',
                                    event: 'link',
                                    category: 'Socials',
                                    label: `NIS Twitter`,
                                })
                            }}
                            aria-label='Twitter'
                            href='https://twitter.com/nisamerica'
                            target='_blank'
                            rel='noreferrer'
                            data-label='Twitter'
                        >
                            <img 
                                alt='X logo'
                                src={`https://nisamerica.com/public/assets/logo/twitter-x-logo-white.png`}
                                style={{ width: '30px', height: 'auto' }}
                            />
                        </a>
                    </div>
                    <div>
                        <a
                            onClick={() => {
                                ReactGA.event({
                                    action: 'click',
                                    event: 'link',
                                    category: 'Socials',
                                    label: `NIS Facebook`,
                                })
                            }}
                            aria-label='Facebook'
                            href='https://www.facebook.com/NISAmericaInc/'
                            target='_blank'
                            rel='noreferrer'
                            className=''
                            data-label='Facebook'
                        >
                            <i className='fab fa-facebook-f fa-2x'></i>
                        </a>
                    </div>
                    <div>
                        <a
                            onClick={() => {
                                ReactGA.event({
                                    action: 'click',
                                    event: 'link',
                                    category: 'Socials',
                                    label: `NIS Instagram`,
                                })
                            }}
                            aria-label='Instagram'
                            href='https://www.instagram.com/nisamerica/'
                            target='_blank'
                            rel='noreferrer'
                            className=''
                            data-label='Instagram'
                        >
                            <i className='fab fa-instagram fa-2x'></i>
                        </a>
                    </div>
                    <div>
                        <a
                            onClick={() => {
                                ReactGA.event({
                                    action: 'click',
                                    event: 'link',
                                    category: 'Socials',
                                    label: `NIS YouTube`,
                                })
                            }}
                            aria-label='YouTube'
                            href='https://www.youtube.com/user/NISAmerica'
                            target='_blank'
                            rel='noreferrer'
                            className=''
                            data-label='YouTube'
                        >
                            <i className='fab fa-youtube fa-2x'></i>
                        </a>
                    </div>
                    <div>
                        <a
                            onClick={() => {
                                ReactGA.event({
                                    action: 'click',
                                    event: 'link',
                                    category: 'Socials',
                                    label: `NIS Twitch`,
                                })
                            }}
                            aria-label='Twitch'
                            href='https://www.twitch.tv/nisamerica'
                            target='_blank'
                            rel='noreferrer'
                            className=''
                            data-label='Twitch'
                        >
                            <i className='fab fa-twitch fa-2x '></i>
                        </a>
                    </div>
                    <div>
                        <a
                            onClick={() => {
                                ReactGA.event({
                                    action: 'click',
                                    event: 'link',
                                    category: 'Socials',
                                    label: `NIS Discord`,
                                })
                            }}
                            aria-label='Discord'
                            href='https://discord.gg/nisa'
                            target='_blank'
                            rel='noreferrer'
                            className=''
                            data-label='Discord'
                        >
                            <i className='fab fa-discord fa-2x'></i>
                        </a>
                    </div>
                </div>
                <div className='mailchimp md:basis-3/6'>
                    <form
                        action='https://nisamerica.us11.list-manage.com/subscribe/post?u=e8fc423eff95099d1ffcc6009&amp;id=349f7d20f9'
                        method='post'
                        id='mc-embedded-subscribe-form'
                        name='mc-embedded-subscribe-form'
                        className='validate'
                        target='_blank'
                        noValidate=''
                    >
                        <div id='mc_embed_signup_scroll' className='mailchimp text-center'>
                            <div>
                                <label htmlFor='mce-EMAIL' className='subscribe text-base'>
                                    Subscribe to our mailing list to get the latest information!
                                </label>
                            </div>
                            <div className=''>
                                <div className='subscribe-wrapper'>
                                    <input
                                        className=''
                                        type='email'
                                        placeholder='Your Email'
                                        value={email}
                                        name='b_e8fc423eff95099d1ffcc6009_349f7d20f9'
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <button
                                        onClick={() => {
                                            ReactGA.event({
                                                action: 'click',
                                                event: 'link',
                                                category: 'Socials',
                                                label: `NIS Subscribe Newsletter`,
                                            })
                                        }}
                                        
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

                <div className='platforms'>
                    <div>
                        <img src={`https://nisamerica.com/ys-ix/about/assets/common/logo/ESRB-RP-T.svg`} alt="" className="esrb" style={{height: '75px'}}/>
                    </div>
                </div>

            <div className='developer'>
                <div>
                    <img
                        style={{
                            width: '180px',
                            height: "auto"
                        }}
                        alt={"Nihon Falom"}
                        src={`https://nisamerica.com/public/assets/logo/falcom-white.png`}
                    />
                </div>
                <div>
                    <img 
                    style={{
                        width: '190px',
                        height: "auto"
                    }}
                    alt='NIS logo' src={`https://nisamerica.com/public/assets/logo/nis.svg`} />
                </div>{' '}
            </div>

            <div className='copyrights'>
                <div className='text-sm text-stone-400  border-t-0 border-stone-700'>
                    <p>© Nihon Falcom Corporation. All rights reserved. Licensed to and published by NIS America, Inc.</p>                  
                </div>{' '}
            </div>
            </div>
        </footer>
    )
}